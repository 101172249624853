.print-container {
  background-color: white;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.page {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100vh;
  page-break-after: always;
  align-items: center;
}

.content {
  flex: 1;
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
}

.first-page {
  width: 40%;
  align-self: center;
}

.new-page {
  page-break-before: always;
  width: 100%;
}

.box {
  border: 8px solid black;
  padding: 18px 0;
  width: 100%;
}

.box .ministry-text {
  font-size: 24px;
  font-weight: bold;
}

.divider {
  width: 100%;
  height: 1px;
  background-color: black;
  margin: 0 0 10px 0;
}

.box .school-name-section {
  margin: 20px 0 10px 0;
  line-height: 24px;
  text-align: center;
}

.box .school-name {
  font-size: 24px;
}

.box .sub-school-name-text {
  font-size: 16px;
  line-height: 16px;
  font-weight: normal;
}

.box .location-section {
  margin: 20px 0 5px 0;
  width: 60%;
}

.box .city,
.box .county {
  font-size: 16px;
  font-weight: normal;
  line-height: 16px;
}

.box .big-title {
  font-size: 40px;
  font-weight: bold;
}

.box .details-section {
  margin: 10px 0 80px 0;
}

.box .detail-text {
  font-size: 22px;
  font-weight: bold;
  line-height: 24px;
}

.box .detail-text .undeline {
  text-decoration: underline;
}

.box .sub-detail-text {
  font-size: 14px;
  font-weight: normal;
  line-height: 14px;
}

.sub-box-text {
  text-align: center;
  margin-top: 10px;
}

.page-count {
  text-align: center;
  font-size: 14px;
  margin-top: auto;
  display: none;
}

.second-page .content {
  width: 50%;
}

.second-page .title {
  margin-bottom: 1.5rem;
}

.second-page .title-text {
  font-size: 32px;
  font-weight: bold;
  line-height: 34px;
}

.second-page .page-content-text {
  text-indent: 2rem;
  font-size: 14px;
}

.third-page .content {
  width: 70%;
}

.third-page .table-content {
  display: flex;
  justify-content: space-between;
  gap: 4px;
}

.third-page table {
  flex: 1;
  border-collapse: collapse;
}

.third-page table,
.third-page th,
.third-page td {
  border: 1px solid black;
}

.third-page th,
.third-page td {
  text-align: left;
  height: 26px;
  min-height: 26px;
}

.third-page th {
  text-align: center;
}

.third-page tr {
  font-size: 16px;
  max-height: 26px;
  height: 26px;
  min-height: 26px;
}

.third-page .table-section {
  margin-bottom: 2rem;
  justify-content: start;
}

.third-page .sub-table-text-section .text {
  font-size: 14px;
  font-weight: normal;
  line-height: 14px;
}

.third-page .table-title {
  font-size: 24px;
  font-weight: bold;
}

.third-page .header {
  font-size: 26px;
  font-weight: bold;
}

.third-page .school-name {
  font-size: 26px;
  font-weight: bold;
}

.third-page .sub-school-name-text {
  font-size: 16px;
  font-weight: normal;
  line-height: 16px;
  text-align: center;
}

.third-page .location-section {
  margin: 2rem 0 2.5rem 0;
  text-align: center;
}

.third-page .city {
  font-size: 16px;
  font-weight: bold;
  line-height: 16px;
}

.third-page .class-catalogue-section {
  text-align: center;
  margin-bottom: 2rem;
}

.third-page .class-catalogue-text {
  font-size: 32px;
  font-weight: bold;
  line-height: 32px;
}

.third-page .sub-class-catalogue-text {
  font-size: 16px;
  font-weight: normal;
  line-height: 16px;
}

.third-page .school-year {
  font-size: 24px;
  font-weight: bold;
  line-height: 24px;
  margin-bottom: 2rem;
}

.third-page .position-text {
  font-size: 18px;
  font-weight: bold;
  line-height: 20px;
}

.fourth-page table {
  border-collapse: collapse;
}

.fourth-page table,
.fourth-page th,
.fourth-page td {
  border: 1px solid black;
}

.fourth-page th,
.fourth-page td {
  padding: 2px 5px;
  text-align: left;
  font-weight: normal;
}

.fourth-page th {
  text-align: center;
}

.fourth-page tr {
  font-size: 16px;
  line-height: 16px;
  height: fit-content;
}

.fourth-page .student-name {
  font-size: 20px;
  font-weight: bold;
  line-height: 20px;
  font-style: italic;
}

.fourth-page .tg-0pky {
  font-size: 10px;
  padding: 4px;
  vertical-align: middle;
  text-align: center;
}

.fourth-page .big-cell {
  padding: 80px 4px 80px 4px;
}

.fourth-page .wide-cell {
  font-size: 10px;
  padding: 80px 24px 80px 12px;
}

.fourth-page .grade-text,
.fourth-page .absence-text {
  color: #0f31f2;
}

.fourth-page .average-cell {
  font-size: 11px;
  vertical-align: middle;
}

.fifth-page .tg {
  border-collapse: collapse;
  border-spacing: 0;
}
.fifth-page .tg td {
  border-color: black;
  border-style: solid;
  border-width: 1px;
  font-family: Arial, sans-serif;
  font-size: 14px;
  overflow: hidden;
  padding: 8px 5px;
  word-break: normal;
}
.fifth-page td .text {
  line-height: 14px;
  font-size: 14px;
}
.fifth-page .tg .tg-0lax {
  text-align: left;
  vertical-align: middle;
}

.fifth-page th {
  border-color: black;
  border-style: solid;
  border-width: 1px;
  font-family: Arial, sans-serif;
  font-size: 14px;
  font-weight: normal;
  overflow: hidden;
  padding: 10px 5px;
  word-break: normal;
  text-align: center !important;
}

.fifth-page .value-cell {
  vertical-align: middle !important;
  text-align: center !important;
}

.fifth-page .title {
  font-size: 26px;
  font-weight: bold;
}

.fifth-page .content {
  width: 70%;
}

.fifth-page .position-text {
  font-size: 18px;
  font-weight: bold;
  line-height: 24px;
}

.sixth-page .content {
  width: 80%;
}

.sixth-page .title {
  font-size: 26px;
  font-weight: bold;
}

.sixth-page .tg {
  border-collapse: collapse;
  border-spacing: 0;
}
.sixth-page .tg td {
  border-color: black;
  border-style: solid;
  border-width: 1px;
  font-family: Arial, sans-serif;
  font-size: 14px;
  overflow: hidden;
  padding: 10px 5px;
  word-break: normal;
}
.sixth-page .tg th {
  border-color: black;
  border-style: solid;
  border-width: 1px;
  font-family: Arial, sans-serif;
  font-size: 14px;
  font-weight: bold;
  overflow: hidden;
  padding: 10px 5px;
  word-break: normal;
  vertical-align: middle !important;
  text-align: center !important;
}

.sixth-page .tg .tg-0lax {
  text-align: left;
  vertical-align: top;
}

.seventh-page .content {
  width: 70%;
}

.seventh-page .title {
  font-size: 26px;
  font-weight: bold;
}

.seventh-page .school-name {
  font-size: 24px;
  font-weight: bold;
}

.seventh-page .sub-school-name {
  font-size: 20px;
  font-weight: normal;
  line-height: 20px;
}

.seventh-page .table-content {
  display: flex;
  justify-content: space-between;
  gap: 12px;
}

.seventh-page table {
  flex: 1;
  border-collapse: collapse;
}

.seventh-page table,
.seventh-page th,
.seventh-page td {
  border: 1px solid black;
}

.seventh-page th,
.seventh-page td {
  padding: 0 4px;
  text-align: left;
}

.seventh-page th {
  text-align: center;
}

.seventh-page tr {
  font-size: 16px;
  line-height: 16px;
  max-height: 24px;
  height: 24px;
  min-height: 24px;
}

.seventh-page .table-section {
  justify-content: start;
}

.seventh-page .sub-table-text-section .text {
  font-size: 14px;
  font-weight: normal;
  line-height: 14px;
}

.seventh-page .table-title {
  font-size: 24px;
  font-weight: bold;
}

@media print {
  body {
    -webkit-print-color-adjust: exact;
  }
  @page {
    size: A3 landscape;
    margin: 5mm 5mm 5mm 5mm;
  }

  .page-count {
    display: block;
  }
}
