@media print {
  body {
    -webkit-print-color-adjust: exact;
  }
  @page {
    size: A4;
    margin: 20mm;
    scale: 0.7;
  }
  .print\:border-black {
    border-color: black !important;
  }
  .print\:px-8 {
    padding-left: 2rem !important;
    padding-right: 2rem !important;
  }
  .print\:py-8 {
    padding-top: 2rem !important;
    padding-bottom: 2rem !important;
  }
  .print\:gap-4 {
    gap: 1rem !important;
  }
  .page-break {
    page-break-before: always;
  }
}
